.App {
  display: flex;
  height: 100vh;
  width: 100vw;
  flex-direction: column;
}

.colors {
  flex: 1;
  display: flex;
  flex-direction: row;
}

.colorBar {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2em;
  color: #000000aa;
}

.nav {
  tab-size: 4;
  font-family: inherit;
  line-height: inherit;
  --tw-bg-opacity: 1;
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  background-color: white;
  border-color: #e5e7eb;
  --tw-shadow: 0 0 #0000;
  --tw-ring-inset: var(--tw-empty, /*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  display: flex;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.nav-item {
  tab-size: 4;
  font-family: inherit;
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: #e5e7eb;
  --tw-shadow: 0 0 #0000;
  --tw-ring-inset: var(--tw-empty, /*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  text-decoration: inherit;
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  border-radius: 0.375rem;
  display: inline-flex;
  align-items: center;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  --tw-text-opacity: 1;
  color: rgba(17, 24, 39, var(--tw-text-opacity));
}
